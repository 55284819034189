import React, { useState } from "react";
import "./MyModal.css";

import {
    Container,
    Header,
    Image,
    Label,
    Loader,
    Modal,
} from "semantic-ui-react";

const MyModal = ({ parcoursElement }) => {
    const [open, setOpen] = useState(false);

    return parcoursElement != null ? (
        <Modal
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
            open={open}
            dimmer="blurring"
            closeIcon
            trigger={
                <div>
                    {parcoursElement.title && (
                        <h3 className="vertical-timeline-element-title">
                            {parcoursElement.title}
                        </h3>
                    )}
                    {parcoursElement.subTitle && (
                        <h4 className="vertical-timeline-element-subtitle">
                            {parcoursElement.subTitle}
                        </h4>
                    )}
                    {parcoursElement.desc && <p>{parcoursElement.desc}</p>}
                    {parcoursElement.label && (
                        <div className="parcours-label-group">
                            {parcoursElement.label.map((value, index) => (
                                <Label
                                    key={index}
                                    color={value.color}
                                    className="parcours-label"
                                >
                                    {value.name}
                                </Label>
                            ))}
                        </div>
                    )}
                </div>
            }
        >
            <Modal.Header>{parcoursElement.title}</Modal.Header>
            <Modal.Content image scrolling>
                {parcoursElement.logo ? (
                    <Image
                        size="medium"
                        src={parcoursElement.logo}
                        wrapped
                        rounded
                    />
                ) : (
                    <Loader
                        active
                        inline
                        style={{ margin: "2rem 4.5rem 0 3rem" }}
                    />
                )}
                <Modal.Description className="project-card-modal-description">
                    <Header>Description</Header>
                    <p>
                        {parcoursElement.descModal
                            ? parcoursElement.descModal
                            : parcoursElement.desc}
                    </p>
                    <Header>Technologies</Header>
                    <Container>
                        {parcoursElement.label &&
                            parcoursElement.label.map((value, index) => (
                                <Label
                                    key={index}
                                    color={value.color}
                                    className="project-card-label"
                                >
                                    {value.name}
                                </Label>
                            ))}
                    </Container>
                </Modal.Description>
            </Modal.Content>
        </Modal>
    ) : (
        <Loader />
    );
};

export default MyModal;
