import imgCaM from "../img/bkg.jpg";
import imgOpus from "../img/the-vault-5_1280.jpg";

const label = {
    Html: { name: "HTML", color: "orange" },
    Css: { name: "CSS", color: "blue" },

    Python: { name: "Python", color: "yellow" },

    Java: { name: "Java", color: "red" },
    JavaFX: { name: "JavaFX", color: "red" },

    Php: { name: "PHP", color: "blue" },
    Lumen: { name: "Lumen", color: "blue" },

    JavaScript: { name: "JavaScript", color: "yellow" },
    React: { name: "React", color: "teal" },
    Redux: { name: "Redux", color: "purple" },
    "Semantic UI": { name: "Semantic UI", color: "green" },
    Vuejs: { name: "Vue.js", color: "green" },

    NodeJS: { name: "NodeJS", color: "green" },
    Express: { name: "Express", color: "green" },

    MySQL: { name: "MySQL", color: "orange" },
    PostGreSQL: { name: "PostGreSQL", color: "blue" },
    MongoDB: { name: "MongoDB", color: "green" },
    HeidiSQL: { name: "HeidiSQL", color: "green" },

    Git: { name: "Git", color: "orange" },
    Scrum: { name: "SCRUM", color: "pink" },
    Markdown: { name: "Markdown", color: "grey" },
    RestAPI: { name: "RestAPI", color: "grey" },

    Linux: { name: "Linux", color: "grey" },
    Windows: { name: "Windows", color: "blue" },
    MacOS: { name: "MacOS", color: "grey" },

    Bash: { name: "Bash", color: "grey" },
    PowerShell: { name: "PowerShell", color: "grey" },

    VirtualBox: { name: "VirtualBox", color: "blue" },
    Docker: { name: "Docker", color: "blue" },
    Vagrant: { name: "Vagrant", color: "blue" },

    Terraform: { name: "Terraform", color: "purple" },
    Ansible: { name: "Ansible", color: "red" },
    GCP: { name: "Google Cloud Platform", color: "blue" },
    Azure: { name: "Azure", color: "blue" },

    Fortinet: { name: "Fortinet", color: "red" },
    Cisco: { name: "Cisco", color: "blue" },

    AI: { name: "Intelligence Artificielle", color: "grey" },
};

export const projects = [
    {
        title: "BetaLoWeen",
        desc: "BetaLoWeen est un site réalisé en 24h lors d'un hackathon sur le thème d'halloween, il vous présente divers films d'horreur ainsi que plusieurs manières de les filtrer.",
        img: "https://94.citoyens.com/wp-content/blogs.dir/2/files/2019/10/halloween-vasyl-dolmatov-4pmproduction-stocklib.jpg",
        link: null,
        label: [label.React, label.RestAPI, label.Html, label.Css],
    },
    {
        title: "HeroesFighter",
        desc: "Heroes Fighter est un jeu de cartes interactif vous offrant la possibilité de choisir un de vos super-héros favoris et le faire combattre contre d'autres.",
        img: "https://zupimages.net/up/19/43/d71r.jpeg",
        link: "https://heroesfighter.netlify.app/",
        label: [label.React, label.RestAPI, label.Html, label.Css, label.Scrum],
    },
    {
        title: "Opus",
        desc: "Opus est une application web mobile qui a pour objectif de mettre à disposition des espaces de travail pour les professionnels.",
        img: imgOpus,
        link: "https://github.com/HugoEyle/reims-0919-js-opus",
        label: [
            label.React,
            label.Express,
            label.Redux,
            label.Html,
            label.Css,
            label.MySQL,
            label.Scrum,
        ],
    },
    {
        title: "Mise en place d'un hébergeur de développement logiciel",
        desc: "Mise en place de Gitea ainsi que d'une notice d'utilisation informant l'utilisateur sur comment installer et utiliser Gitea.",
        img: "https://kinsta.com/fr/wp-content/uploads/sites/4/2022/05/what-is-web-hosting-1-1024x512.jpg",
        link: null,
        label: [label.VirtualBox, label.Linux, label.Markdown],
    },
    {
        title: "Teenagers Around The World",
        desc: "Teenagers Around The World est une application permettant de mettre en relation des adolescents avec leur correspondant pour planifier un voyage dans un pays étranger.",
        img: "https://anagennisiedu.gr/wp-content/uploads/2015/02/Teenagers.jpg",
        link: null,
        label: [label.Java, label.JavaFX, label.PostGreSQL, label.Scrum],
    },
    {
        title: "Monster Hunter",
        desc: "Monster Hunter est un jeu dans lequel vous pouvez jouer le monstre ou le chasseur, le but en tant que monstre est d'échapper au chasseur en sortant du labyrinthe. Le but en tant que chasseur est de tuer le monstre avant qu'il ne sorte du labyrinthe.",
        img: imgCaM,
        link: null,
        label: [label.Java, label.JavaFX, label.AI, label.Scrum],
    },
    {
        title: "Automatisation de la mise en place d'instance ERP Odoo",
        desc: "Automatisation de la mise en place d'instance ERP Odoo sur plusieurs serveurs. Un serveur PostgreSQL contenant les bases de données de chaque instance Odoo, un serveur contenant les sauvegardes journalières des bases de données et un serveur Docker avec Traefik et les instances Odoo.",
        img: "https://www.shutterstock.com/image-vector/automation-software-gears-icon-made-600nw-2452531251.jpg",
        link: null,
        label: [
            label.Linux,
            label.Docker,
            label.Bash,
            label.PostGreSQL,
            label.Markdown,
        ],
    },
];

export const parcours = [
    {
        title: "Ingenieur réseau, sécurité et télécom",
        subTitle: "ACIPIA, Wambrechies",
        date: "Depuis Octobre 2024",
        logo: "https://www.pagesjaunes.fr/media/ugc/24/05/65/00/00/75/5f/3f/63/41/5e542405650000755f3f6341/5e542405650000755f3f6342.png",
        descModal:
            "Chez Acipia, je travaille sur l’administration des infrastructures réseau et téléphonie, en contribuant à l’optimisation des systèmes et à la sécurisation des communications. J’interviens également sur des projets d’automatisation et de supervision pour garantir la performance et la continuité des services.",
        icon: "building",
        iconStyle: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            background: "rgb(213, 29, 29)",
            color: "#fff",
        },
        position: "right",
        label: [
            label.Linux,
            label.Windows,
            label.Bash,
            label.PowerShell,
            label.Fortinet,
            label.Cisco,
        ],
    },
    {
        title: "Évaluateur de systèmes informatiques",
        subTitle: "Ananke Inc., Montréal, Québec, Canada",
        date: "Avril 2024 - Juillet 2024",
        logo: "https://assets.hellodarwin.com/companies/logos/25b75021-63ab-487b-81d1-91d5f7592d73.jpeg",
        descModal:
            "Lors de mon stage chez Ananke Inc., j’ai travaillé sur l’intégration de Merge.dev, une API unifiée, et la création d’une affiche interactive pour la présentation des projets de l’entreprise. J’ai également amélioré l’affichage des données via des API dynamiques et optimisé l’expérience utilisateur en utilisant Vue.js, Laravel et JavaScript. Cette expérience m’a permis de renforcer mes compétences en développement web tout en découvrant le travail en équipe dans un environnement professionnel.",
        icon: "building",
        iconStyle: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            background: "rgb(213, 29, 29)",
            color: "#fff",
        },
        position: "right",
        label: [
            label.MacOS,
            label.Bash,
            label.Git,
            label.Html,
            label.Css,
            label.JavaScript,
            label.Vuejs,
        ],
    },
    {
        title: "BUT Informatique",
        subTitle: "Institut Universitaire de Technologie, Lille",
        desc: "Option Déploiement d’applications communicantes et sécurisées",
        date: "Depuis Septembre 2022",
        logo: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTIeSf_IIjg4D6eJL8NZWodorlAeDEMdY9ugg&s",
        icon: "book",
        iconStyle: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            background: "rgb(33, 150, 243)",
            color: "#fff",
        },
        position: "left",
        label: [
            label.Bash,
            label.PostGreSQL,
            label.Python,
            label.Java,
            label.Vagrant,
            label.Terraform,
            label.Ansible,
            label.GCP,
            label.Azure,
        ],
    },
    {
        title: "Baccalauréat",
        subTitle: "MicroLycee, Reims",
        desc: "Option SIN",
        date: "Septembre 2021 - Juillet 2022",
        icon: "book",
        iconStyle: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            background: "rgb(33, 150, 243)",
            color: "#fff",
        },
        position: "left",
    },
    {
        title: "Développeur JavaScript & PhP",
        subTitle: "JVS-Mairistem, Saint-Martin-sur-le-Pré",
        date: "Février 2020 - Juin 2020",
        logo: "https://media.licdn.com/dms/image/v2/D4E0BAQEc40jjiCPZ4A/company-logo_200_200/company-logo_200_200/0/1719257649559/mairistembyjvs_logo?e=2147483647&v=beta&t=mkAZ0aYDinwK5lEDO6mU2yLW10vEtPl_4c8r4DO4dPs",
        descModal: "Loading...", // TODO
        icon: "building",
        iconStyle: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            background: "rgb(213, 29, 29)",
            color: "#fff",
        },
        position: "right",
        label: [
            label.Bash,
            label.Git,
            label.Php,
            label.JavaScript,
            label.MongoDB,
            label.React,
            label["Semantic UI"],
            label.Lumen,
            label.Scrum,
        ],
    },
    {
        title: "Developpeur Full Stack",
        subTitle: "Wild Code School, Reims",
        date: "Septembre 2019 - Février 2020",
        icon: "book",
        iconStyle: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            background: "rgb(33, 150, 243)",
            color: "#fff",
        },
        position: "left",
        label: [
            label.Linux,
            label.Bash,
            label.Git,
            label.JavaScript,
            label.MySQL,
            label.React,
            label.Scrum,
        ],
    },
];

export const homePageData = {
    title: "BUT Informatique | Déploiement d'application Communicantes et Sécurisées",
    subTitle:
        "Recherche Alternance (2025-2026) : Administrateur Systèmes et Réseaux (Cybersécurité)",
    desc: "Passionné d'informatique et de nouvelles technologies depuis mon plus jeune âge, j'ai tout d'abord été attiré par le développement puis me suis tourné vers la gestion de l'infrastructure informatique. Intéressé par la cybersécurité, je souhaite travailler dans ce milieu.",
};
